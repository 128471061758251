<template>
  <Page full>
    <grid
      class="large-only"
      title="Document templates"
      type="template"
      :isMain="true"
      :order="[['company_id', 'desc']]"
      :permanentFilters="{
        template_type_base_type: 'd',
        template_type_id: '!101'
      }"
    >
    </grid>
  </Page>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'
import SuggestionButton from '../ui/SuggestionButton.vue'

export default {
  mixins: [PageMixin],
  name: '',
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {
    editTemplate(template) {
      this.$router.push(`template/${template.template_id}`).catch(() => {})
    }
  },
  components: {
    SuggestionButton,
    Grid
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.large-only-message {
  visibility: hidden;
  @media (max-width: 992px) {
    visibility: visible;
  }
}
</style>
